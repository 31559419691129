<template>
  <div class="add__response--wrapper">
    <!-- reference URL ( https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#rendering ) -->
    <ValidationObserver v-slot="{ invalid }" ref="form" slim>
      <form class="response-form" @submit.prevent="submitResponse">
        <div
          :class="[
            'add__response--inner',
            { 'height-increased': isHeightIncreased }
          ]"
        >
          <div class="response__input--wrapper">
            <div class="post-user">
              <div
                class="user-image"
                :class="{
                  'user-bg': hasProfileImage
                }"
              >
                <img :src="userImageUrl" alt="avatar" />
              </div>
            </div>
            <quill-editor
              v-model="responseBody"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
              :class="[
                'add__response--input',
                {
                  'toxic-input': isResponseToxic,
                  'input-focused': isEditorFocused || responseBody !== ''
                }
              ]"
            ></quill-editor>
            <div class="add-emoji">
              <ImageButton
                :defaultIcon="addEmojiIcon"
                :hoveredIcon="addEmojiHoverIcon"
                :focusedIcon="addEmojiHoverIcon"
                :pressedIcon="addEmojiPressedIcon"
                altText="Emoji"
              />
            </div>
          </div>
          <LxpLoader class="response-spinner" v-if="isResponseLoading" />
          <div
            :class="[
              'submit-response',
              {
                'disable-response': invalid || disableResponseBtn,
                hovered: isAddResponseIconHovered || isResponseDropdownOpen
              }
            ]"
            ref="dropdownContainer"
            v-else-if="getSelectedDiscussionPost?.is_facilitator"
          >
            <img
              :src="submitResponseIcon"
              alt="Submit"
              @mouseover="isAddResponseIconHovered = true"
              @mouseout="isAddResponseIconHovered = false"
              @click="responseDropdown"
            />
            <div class="share__response--list" v-if="isResponseDropdownOpen">
              <Button
                class="share__list--item"
                type="submit"
                @click="setSubmitType('publicly')"
              >
                <span class="title">
                  {{ $t("discussions.new_response.button.publicly.title") }}
                </span>
                <span class="subtext">
                  {{ $t("discussions.new_response.button.publicly.content") }}
                </span>
              </Button>
              <Button
                class="share__list--item"
                type="submit"
                @click="setSubmitType('privately')"
              >
                <span class="title">
                  {{ $t("discussions.new_response.button.privately.title") }}
                </span>
                <span class="subtext">
                  {{ $t("discussions.new_response.button.privately.content") }}
                </span>
              </Button>
            </div>
          </div>
          <button
            :disabled="invalid || disableResponseBtn"
            :class="[
              'submit-response',
              {
                hovered: isAddResponseIconHovered
              }
            ]"
            type="submit"
            v-else
          >
            <img
              :src="submitResponseIcon"
              alt="Submit"
              @mouseover="isAddResponseIconHovered = true"
              @mouseout="isAddResponseIconHovered = false"
            />
          </button>
        </div>
        <div class="error-text toxic-error" v-if="isResponseToxic">
          {{ $t("discussions.new_post.toast.toxic.response.note") }}
        </div>
        <div class="error-text" v-if="showError && responseBody !== ''">
          {{ $t("discussions.new_response.error.title") }}
        </div>
        <div
          class="counter-error"
          v-if="responseBodyLength >= textLimit && responseBody !== ''"
        >
          {{ responseBodyLength }} / {{ textLimit }}
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpLoader } from "didactica";
import { quillEditor } from "vue-quill-editor";
import "quill-mention";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";

export default {
  components: { LxpLoader, quillEditor },
  data() {
    return {
      responseBody: "",
      defaultAvatar: require("@/assets/images/menu/avatar.svg"),
      defaultGreyAvatar: require("@/assets/images/menu/avatar-grey.svg"),
      addResponseIcon: require("@/assets/images/vle/forums/response/response-submit.svg"),
      addResponseIconHover: require("@/assets/images/vle/forums/response/response-submit-hover.svg"),
      addResponseDisabledIcon: require("@/assets/images/vle/forums/response/response-submit-disabled.svg"),
      addEmojiIcon: require("@/assets/images/vle/forums/response/add-emoji.svg"),
      addEmojiHoverIcon: require("@/assets/images/vle/forums/response/add-emoji-hover.svg"),
      addEmojiPressedIcon: require("@/assets/images/vle/forums/response/add-emoji-pressed.svg"),
      isAddResponseIconHovered: false,
      isResponseLoading: false,
      textLimit: 1500,
      showUsersList: false,
      searchUser: "",
      responseBodyLength: 0,
      mentionList: [],
      editorOption: {
        theme: "bubble",
        placeholder: this.$t("discussions.heading.add_a_response"),
        modules: {
          toolbar: false,
          mention: {
            allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            showDenotationChar: false,
            positioningStrategy: "normal",
            source: (searchTerm, renderList) => {
              let values = this.getMentionsList.map(item => ({
                id: item.username,
                value: item.display_name
              }));
              const spaceCount = (searchTerm.match(/\s/g) || []).length;
              if (spaceCount > 1) {
                return renderList([], searchTerm);
              }
              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++) {
                  if (
                    values[i].value
                      ?.toLowerCase()
                      .indexOf(searchTerm?.trim()?.toLowerCase()) >= 0
                  ) {
                    matches.push(values[i]);
                  }
                }
                renderList(matches, searchTerm);
              }
            },
            renderItem: user => {
              const img = this.fetchUserAvatar(user);
              return `<span class="img"><img src="${img}" alt="${user.value}" /></span>
                      <span class="text">${user.value}</span>`;
            }
          }
        }
      },
      isResponseToxic: false,
      showError: false,
      isResponseDropdownOpen: false,
      submitType: "",
      editorHeight: 0,
      isHeightIncreased: false,
      isEditorFocused: false
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedDiscussionPost",
      "getTopicId",
      "getAuthProfile",
      "getMentionsList"
    ]),
    hasProfileImage() {
      return this.getAuthProfile?.profile_image_url !== null;
    },
    userImageUrl() {
      return this.hasProfileImage
        ? this.getAuthProfile?.profile_image_url
        : this.defaultAvatar;
    },
    submitResponseIcon() {
      if (this.disableResponseBtn) {
        return this.addResponseDisabledIcon;
      } else if (this.isAddResponseIconHovered || this.isResponseDropdownOpen) {
        return this.addResponseIconHover;
      } else {
        return this.addResponseIcon;
      }
    },
    disableResponseBtn() {
      return (
        !this.responseBody ||
        this.isTextLimitExceeded ||
        this.$refs?.myQuillEditor?.quill?.getText?.()?.trim?.()?.length === 0
      );
    },
    filteredUsersList() {
      if (!this.searchUser) {
        return this.getMentionsList;
      } else {
        return this.getMentionsList.filter(user =>
          user.display_name
            ?.toLowerCase()
            .includes(this.searchUser?.toLowerCase())
        );
      }
    },
    usersList() {
      return this.getMentionsList.map(item => ({
        label: item.display_name,
        value: item.display_name,
        profile_image_url: item.profile_image_url
      }));
    },
    editor() {
      return this.$refs?.myQuillEditor?.quill;
    },
    isTextLimitExceeded() {
      return this.responseBodyLength > this.textLimit;
    }
  },
  created() {
    this.fetchUsersList();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    onEditorBlur() {
      this.isEditorFocused = false;
      if (this.responseBody) {
        this.checkToxicLanguage();
      } else {
        this.isResponseToxic = false;
      }
    },
    onEditorFocus() {
      this.isEditorFocused = true;
    },
    onEditorReady() {
      this.updateEditorHeight();
    },
    fetchUserAvatar(user) {
      const username = user.id;
      const _user = this.getMentionsList?.find(
        item => item.username === username
      );
      if (_user.profile_image_url) {
        return _user.profile_image_url;
      } else {
        return this.defaultGreyAvatar;
      }
    },
    fetchUsersList() {
      this.$store.dispatch("getCourseUsersList", {
        course_id: this.$route.params.id
      });
    },
    setSubmitType(type) {
      this.submitType = type;
    },
    extractMentionedUsers() {
      const mentionedUsers = [];
      const textDelta = this.$refs?.myQuillEditor?.quill?.getContents();
      if (textDelta.ops) {
        textDelta.ops.forEach(item => {
          if (item.insert && item.insert.mention) {
            mentionedUsers.push(item.insert.mention);
          }
        });
      }
      return mentionedUsers;
    },
    updateResponseBodyLength() {
      const text = this.$refs?.myQuillEditor?.quill?.getText();
      const mentionedUsers = this.extractMentionedUsers();
      let mentionedUserCharacters = mentionedUsers.reduce(
        (acc, item) => acc + item.value.length + 2, //2 for special characters being added in mention
        0
      );
      const totalMentionsArray = mentionedUsers.map(user => user.id);
      const totalLength = (text?.length ?? 0) + mentionedUserCharacters - 1; // Don't count \n at the end
      this.responseBodyLength = totalLength;
      this.mentionList = totalMentionsArray;
    },
    submitResponse() {
      this.showError = false;
      this.isAddResponseIconHovered = false;
      this.isResponseLoading = true;
      let isPrivateValue;
      if (this.getSelectedDiscussionPost?.is_facilitator) {
        isPrivateValue = this.submitType === "privately";
      } else {
        isPrivateValue = this.mentionList.length > 0;
      }
      let obj = {
        course_id: this.$route.params.id,
        post_id: this.getSelectedDiscussionPost.id,
        body: this.responseBody,
        mentioned_users: this.mentionList.join(","),
        is_private: isPrivateValue
      };
      const formData = new FormData();
      for (const [key, value] of Object.entries(obj)) {
        formData.append(key, value);
      }
      this.$store
        .dispatch("createPostResponse", formData)
        .then(() => {
          this.isResponseLoading = false;
          this.editorHeight = 0;
          this.isHeightIncreased = false;
          this.isResponseDropdownOpen = false;
          this.isResponseToxic = false;
          this.getPostDetail();
          this.resetForm();
          this.$emit("reloadResponses");
        })
        .catch(err => {
          this.isResponseLoading = false;
          this.isResponseDropdownOpen = false;
          if (err.response.data.toxic) {
            this.isResponseToxic = true;
            this.$bvToast.show("toxic-response-toast");
          } else {
            this.showError = true;
          }
        });
    },
    getPostDetail() {
      this.$store.dispatch("getPostDetails", {
        postId: this.getSelectedDiscussionPost.id
      });
    },
    resetForm() {
      this.responseBody = "";
      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    renderHTML(data) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");
      const textContent = doc.body.textContent;
      return textContent;
    },
    async checkToxicLanguage() {
      let toxicText = "";
      toxicText = this.renderHTML(this.responseBody);
      const response = await this.$store.dispatch("checkToxicLanguage", {
        text: toxicText,
        language: ""
      });
      let isToxic = Math.floor(response.toxicity.score * 10) / 10 > 0.5;
      this.isResponseToxic = isToxic;
      if (isToxic) {
        this.$bvToast.show("toxic-response-toast");
      }
    },
    responseDropdown() {
      this.isResponseDropdownOpen = !this.isResponseDropdownOpen;
    },
    handleClickOutside(event) {
      const dropdownContainer = this.$refs.dropdownContainer;
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        this.isResponseDropdownOpen = false;
      }
    },
    updateEditorHeight() {
      const editorElement = this.$refs.myQuillEditor.quill.root;
      if (editorElement && this.responseBody) {
        const height = editorElement.clientHeight;
        this.editorHeight = height;
        this.isHeightIncreased = height > 40;
      } else {
        this.editorHeight = 0;
        this.isHeightIncreased = false;
      }
    }
  },
  watch: {
    getSelectedDiscussionPost() {
      this.resetForm();
    },
    responseBody() {
      this.updateEditorHeight();
      this.updateResponseBodyLength();
    }
  }
};
</script>
<style lang="scss" scoped>
.add__response--wrapper {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid $brand-neutral-200;
  background-color: $brand-neutral-0;
  margin-top: 32px;
  width: 100%;
  .response-form {
    display: flex;
    flex-direction: column;
  }
  .add__response--inner {
    @include flex-center;
    .response__input--wrapper {
      width: 100%;
      position: relative;
      @include flex-center;
    }
    .post-user {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      .user-image {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        @include flex-horizontal-center;

        img {
          width: 100%;
        }

        &.user-bg {
          border: 2px solid $brand-primary-400;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .add__response--input {
      border: 2px solid transparent;
      border-radius: 24px;
      background-color: $brand-primary-75;
      padding: 4px 60px 4px 52px;
      min-height: 48px;
      width: 100%;
      color: $brand-neutral-700;
      letter-spacing: 0.1px;
      word-break: break-word;
      &:hover,
      &.input-focused {
        background-color: $brand-primary-100;
      }
      .ql-editor {
        padding: 0;
      }
      &.toxic-input {
        border-color: $brand-warning;
      }
    }
    .add-emoji {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
    .submit-response {
      border: none;
      cursor: pointer;
      padding: 0;
      background-color: transparent;
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      @include flex-horizontal-center;
      margin-left: 12px;
      margin-right: 4px;
      position: relative;
      img {
        width: 100%;
      }
      &.hovered {
        background-color: $brand-primary-100;
        outline: 4px solid $brand-primary-100;
      }
      &:disabled,
      &.disable-response {
        pointer-events: none;
      }
    }
    .response-spinner {
      margin-left: 12px;
      margin-right: 4px;
    }
    &.height-increased {
      align-items: flex-end;
      .add-emoji,
      .post-user {
        top: auto;
        transform: none;
        bottom: 4px;
      }
      .submit-response,
      .response-spinner {
        margin-bottom: 7px;
      }
      &:disabled {
        pointer-events: none;
      }
    }
  }
  .share__response--list {
    position: absolute;
    width: 263px;
    left: calc(100% + 8px);
    top: -2px;
    border-radius: 8px;
    background-color: $brand-neutral-0;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    padding: 4px;
    .share__list--item {
      border-radius: 4px;
      border: none;
      padding: 8px;
      @include flex-column-start;
      text-align: left;
      background-color: transparent;
      width: 100%;
      height: auto;
      &:focus,
      &:active,
      &:visited {
        background-color: transparent !important;
      }
      &:hover {
        background-color: $brand-primary-100;
        box-shadow: none;
      }
      .title {
        @include body-medium;
        color: $brand-neutral-700;
      }
      .subtext {
        margin-top: 4px;
        @include body-medium;
        color: $brand-neutral-300;
      }
    }
  }
  .error-text {
    @include label-medium;
    letter-spacing: 0px;
    color: $brand-error-400;
    margin-top: 8px;
    &.toxic-error {
      color: $brand-neutral-300;
    }
  }
  .counter-error {
    padding: 8px 8px 0px 0px;
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 32px);
    @include label-medium;
    color: $brand-error-400;
  }
}
</style>
